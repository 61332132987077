@import '../../_variables.scss';

.pageLoadPlaceholder {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: $mainBackground;

  .loadingContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .text {
      font-family: 'Playfair Display';
      font-size: $fontSizeTitle;
      font-weight: 700;
    }

    .spinner {
      position: relative;
      width: 80px;
      height: 80px;
      margin: 0 auto $spacingSmall;

      &:before,
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &:before {
        width: 80px;
        height: 80px;
        background: url(/images/common/load_spinner.png) no-repeat center;
        background-size: contain;
        animation: rotate 1.2s linear infinite;
        margin-top: -40px;
        margin-left: -40px;
      }

      &:after {
        width: 21px;
        height: 36px;
        background-color: #FFF;
        mask: url(/images/icons/flash.svg) no-repeat center;
        mask-size: contain;
        transition: all 0.1s linear;
        opacity: 0.6;
        animation: blink 2.5s linear infinite;
      }

      @keyframes rotate {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(-360deg);
        }
      }

      @keyframes blink {
        0% {
          opacity: 0.6;
        }
        30% {
          opacity: 0.6;
        }
        50% {
          opacity: 0.75;
        }
        65% {
          opacity: 1;
        }
        80% {
          opacity: 0.6;
        }
        90% {
          opacity: 1;
        }
        100% {
          opacity: 0.6;
        }
      }
    }
  }
}
