@import '../_variables.scss';

.profilePage {
  background-color: #1c1e2b;
  background-image: url('/images/profile/bg.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top center;
  transition: all 0.15s linear;
  padding-bottom: 116px;

  &.notGenesisHolder {
    background-image: none;

    .right {
      margin-top: 80px;
    }
  }

  @media 
    (-webkit-min-device-pixel-ratio: 2), 
    (min-resolution: 192dpi) {
    background-image: url('/images/profile/bg@2x.jpg');
  }

  .container {
    margin-top: 80px;
  }

  .profileImages {
    margin-bottom: $spacingHuge;
    display: flex;
    align-items: center;
    justify-content: center;
    
    @media all and (max-width: $break-point-xs) {
      margin: 80px -30px 30px;
      padding: 0;
      justify-content: flex-start;
      overflow-x: scroll;
      overflow-y: hidden;
      height: 300px;

      &.singleImage {
        .imageWrap {
          margin: 0 auto;
        }
      }
    }

    .imageWrap {
      margin: 0 5px;

      @media all and (min-width: $break-point-xs) {
        &.i0 {
          order: 2;
        }

        &.i1 {
          order: 3;
        }

        &.i2 {
          order: 1;
        }

        &.i1,
        &.i2 {
          transform: scale(0.8);
        }
      }
    }
  }

  .flex {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;

    @media all and (max-width: $break-point-xs) {
      flex-wrap: wrap;
    }

    .blockTitle {
      font-weight: 600;
      font-size: $fontSizeBasePlus;
      line-height: 1.1;
      margin-bottom: $spacingMicro;
      position: relative;

      &.genHolder {
        color: $sixthColor;

        &:after {
          content: 'GEN';
          color: $mainBackground;
          border-radius: $borderRadiusMain;
          background-color: $sixthColor;
          font-size: 12px;
          margin-left: 8px;
          padding: 3px 9px;
          position: relative;
          top: -2px;
        }
      }

      &.ascHolder {
        color: $fifthColor;

        &:after {
          content: 'ASC';
          color: $mainBackground;
          border-radius: $borderRadiusMain;
          background-color: $fifthColor;
          font-size: 12px;
          margin-left: 8px;
          padding: 3px 9px;
          position: relative;
          top: -2px;
        }
      }
    }

    .left {
      width: 320px;
      flex-shrink: 0;

      @media all and (max-width: $break-point-xs) {
        width: 100%;
        margin-right: 0;
      }

      .profileImages {
        margin-bottom: 20px;

        .imageWrap {
          transform: scale(0.8);
        }
      }

      .summary {
        font-size: $fontSizeSmall;
        line-height: 1.7;
      }

      .bio {
        color: $textGreyColor;
        margin-bottom: $spacingBig;
      }

      .links {
        margin-bottom: $spacingBig;

        button {
          margin-bottom: $spacingMicro;
          text-align: left;
          position: relative;
          padding-left: 52px;
    
          @media all and (max-width: $break-point-xs) {
            max-width: 100%;
          }

          &:before {
            display: block;
            content: '';
            background-color: #FFF;
            mask-size: cover;
            position: absolute;
            left: 21px;
            top: 50%;
          }

          &.twitter {
            &:before {
              width: 15px;
              height: 12px;
              mask: url(/images/icons/twitter.svg) no-repeat center;
              margin-top: -6px;
            }
          }

          &.discord {
            background-color: $accentColor;

            &:before {
              width: 17px;
              height: 12px;
              mask: url(/images/icons/discord.svg) no-repeat center;
              margin-top: -6px;
            }
          }

          &.website {
            background-color: $secondaryBackground;

            &:before {
              width: 12px;
              height: 12px;
              mask: url(/images/icons/link.svg) no-repeat center;
              margin-top: -6px;
            }
          }
        }
      }

      .stats {
        display: flex;
        flex-wrap: wrap;
        color: $textDarkGreyColor;
        font-size: $fontSizeSmall;
        margin-bottom: $spacingBig;

        span {
          padding-left: 20px;
          margin-right: 20px;
          margin-bottom: $spacingMicro;
          flex-shrink: 0;
          position: relative;

          &:before {
            display: block;
            content: '';
            background-color: $textDarkGreyColor;
            mask-size: cover;
            position: absolute;
            left: 0;
            top: 50%;
          }

          &.joined:before {
            width: 12px;
            height: 14px;
            mask: url(/images/icons/calendar.svg) no-repeat center;
            margin-top: -7px;
          }

          &.views:before {
            width: 14.6px;
            height: 8px;
            mask: url(/images/icons/eye.svg) no-repeat center;
            margin-top: -4px;
          }

          &.timezone:before {
            width: 12px;
            height: 12px;
            mask: url(/images/icons/clock-filled.svg) no-repeat center;
            margin-top: -6px;
          }
        }
      }

      .roles {
        display: flex;
        flex-wrap: wrap;
        color: $textGreyColor;
        margin-bottom: $spacingBig;

        .role {
          font-size: $fontSizeSmall;
          background-color: $secondaryBackground;
          border-radius: 40px;
          padding: 9px 20px 10px;
          margin-right: $spacingMicro;
          margin-bottom: $spacingMicro;
        }
      }

      .endorseButton {
        background-color: $secondaryBackground;
        padding: $spacingBase;
        text-align: left;
        font-size: $fontSizeSmall;
        color: $textGreyColor;
        line-height: 1.5;
        position: relative;
        margin-bottom: $spacingBig;
        border-radius: $borderRadiusMain;
        display: flex;
        align-items: center;
        cursor: pointer;

        .imageWrap {
          flex-shrink: 0;
          position: relative;
          top: 5px;
        }

        &:before {
          content: '';
          display: block;
          position: absolute;
          width: 30px;
          height: 31px;
          background-image: url('/images/icons/thumb_up.png');
          background-size: cover;
          left: 65px;
          top: 65px;
          z-index: 10;

          @media 
            (-webkit-min-device-pixel-ratio: 2), 
            (min-resolution: 192dpi) {
            background-image: url('/images/icons/thumb_up@2x.png');
          }
        }

        .text {
          margin-left: $spacingBase;

          span {
            color: $primaryColor;
          }
        }
      }

      .authRequired {
        .text {
          text-align: center;
          margin-bottom: 20px;
        }
      }
    }

    .right {
      margin-left: $spacingBasePlus;

      @media all and (max-width: $break-point-xs) {
        margin-left: 0;
      }

      .endorsements {
        margin-top: 20px;

        .endorsement {
          margin-bottom: $spacingBig;
        }
      }

      .seeMoreEndorsements {
        background-color: transparent;
        color: $textDarkGreyColor;
        max-width: 100%;
        box-shadow: none;
        border: 1px solid $secondaryBackground;
        padding: 11px 24px 12px;
        margin-bottom: $spacingBig;
      }

      .experiences {
        margin-top: 20px;

        .experience {
          .header {
            display: flex;
            align-items: center;

            .image {
              width: 75px;
              flex-shrink: 0;
              margin-right: 17px;
              border-radius: 50%;
              overflow: hidden;

              & > span {
                display: block !important;
              }
            }

            .meta {
              .position {
                font-weight: 600;
              }

              .period {
                color: $textDarkGreyColor;
                font-size: $fontSizeSmall;
              }
            }
          }

          &:last-child {
            .project:before {
              display: none;
            }
          }

          .project {
            padding-left: 92px;
            color: $textGreyColor;
            font-size: $fontSizeSmall;
            padding-bottom: 80px;
            position: relative;

            &:before {
              content: '';
              display: block;
              width: 4px;
              position: absolute;
              top: 5px;
              bottom: 15px;
              background-color: $secondaryBackground;
              border-radius: 30px;
              left: 35px;
            }

            .name {
              font-weight: 600;
            }

            .description {
              margin-bottom: $spacingMicro;
            }

            .twitter {
              position: relative;
              font-size: $fontSizeSmall;
              color: $textGreyColor;
              background-color: $secondaryBackground;
              border-radius: 40px;
              padding: 7px 13px 7px 31px;
              text-align: left;
              width: fit-content;

              &:before {
                display: block;
                content: '';
                background-color: #FFF;
                mask-size: cover;
                position: absolute;
                left: 11px;
                top: 50%;
                width: 15px;
                height: 12px;
                mask: url(/images/icons/twitter.svg) no-repeat center;
                margin-top: -6px;
              }
            }
          }
        }
      }
    }
  }
}
